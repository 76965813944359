import { RippleEffect } from '../atom/RippleEffect/RippleEffect';

// Defines the style effect the component will take
export type Effect = 'ripple' | boolean;

// Determine the effect/animation if any is specified
export const getEffect = (type: Effect) => {
  switch (type) {
    case 'ripple':
      return RippleEffect;
    default:
      return null;
  }
};
