import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { I18nMessage } from '@zento-lib/components/Base/types';

import { Effect, getEffect } from '../../helpers/effects';

import type { IButton } from './Button.d';
import style from './style.scss';

// Defines the button type attributes supported
export type ButtonType = 'button' | 'submit' | 'reset' | 'link';

// Defines the different style types for button
export type ButtonStyle = 'primary' | 'secondary' | 'tertiary' | 'ghost';

/**
 * Button
 *
 * Button component allowing to create submit, reset, and ghost buttons.
 **/
@Component({})
export class Button extends BaseComponent<IButton, unknown> {
  /**
   * The id attribute specifies a unique id for an HTML element (the value must be unique within the HTML document).
   */
  @Prop({ type: [Number, String], default: undefined })
  id?: number | string;

  /**
   * A value indicating the expected behavior of the button
   */
  @Prop({ type: String, default: 'button' })
  type?: ButtonType;

  /**
   * A name for the control. This name will be sent by the browser to the processing agent,
   * paired with the content of the value attribute.
   * Both attributes together will conform a name-value pair that will be used to process the data.
   */
  @Prop({ type: String, default: undefined })
  name?: string;

  /**
   * A text representing advisory information for the element that could act as description, title, label, etc.
   * Browsers usually show this information in a 'tool tip' (a small box of text) in response to some events
   */
  @Prop({ type: [Object, String], default: undefined })
  title?: I18nMessage;

  /**
   * A boolean value indicating whether the control is disabled or not.
   * If the attribute takes the value 'disabled' or if it's just present,
   * the control will be disabled.
   */
  @Prop({ type: Boolean, default: false })
  disabled?: boolean;

  /**
   * The visibility property sets or returns whether an element should be visible.
   */
  @Prop({ type: Boolean, default: true })
  visible?: boolean;

  /**
   * An event that will carry out the process when the conditions inherent to the event they're associated with, are met
   */
  @Prop({ type: Function, default: () => undefined })
  handler?: (ev: Event) => void;

  /**
   * Custom attribute. These attribute define author's custom data for the element they declared
   * and are designed to be used, mostly, with scripts.
   */
  @Prop({ type: [Object, String], default: undefined })
  dataLabel?: I18nMessage;

  /**
   * Custom attribute. These attribute define author's custom data for the element they declared
   * and are designed to be used, mostly, with scripts.
   */
  @Prop({ type: [Object, String], default: undefined })
  dataTooltip?: I18nMessage;

  /**
   * An attribute used to define a string that labels the current element
   */
  @Prop({ type: [Object, String], default: undefined })
  ariaLabel?: I18nMessage;

  /**
   * A value which will render a different css
   */
  @Prop({ type: String, required: true })
  styleType: ButtonStyle;

  /**
   * Value indicating the button effect/animation if any is specified
   */
  @Prop({ type: [String, Boolean], default: 'ripple' })
  effect?: Effect;

  /**
   * Determines loader data
   */
  @Prop({ type: Boolean, default: false })
  loader?: boolean;

  /**
   * Determines data id, needed for qa tests
   */
  @Prop({ type: String, default: '' })
  dataTestId?: string;

  render() {
    const props: Record<string, any> = {
      id: this.id,
      type: this.type,
      name: this.getTranslation(this.name),
      title: this.getTranslation(this.title),
      'data-label': this.getTranslation(this.dataLabel),
      'data-tooltip': this.getTranslation(this.dataTooltip),
      'aria-label': this.getTranslation(this.ariaLabel),
      disabled: this.disabled,
      visible: this.visible,
      'data-testid': this.dataTestId,
    };

    const Effect: BaseComponent<any, unknown> | any = getEffect(this.effect);

    return this.effect ? (
      <Effect>
        <button
          {...props}
          onClick={this.handler}
          class={{
            [style.button]: true,
            [style.primaryButton]: this.styleType === 'primary',
            [style.secondaryButton]: this.styleType === 'secondary',
            [style.tertiaryButton]: this.styleType === 'tertiary',
            [style.ghostButton]: this.styleType === 'ghost',
            [style.loading]: this.loader,
          }}>
          <span>
            {this.loader ? <span class={style.loader} /> : null}
            <slot />
          </span>
        </button>
      </Effect>
    ) : (
      <button
        {...props}
        onClick={this.handler}
        class={{
          [style.button]: true,
          [style.primaryButton]: this.styleType === 'primary',
          [style.secondaryButton]: this.styleType === 'secondary',
          [style.tertiaryButton]: this.styleType === 'tertiary',
          [style.ghostButton]: this.styleType === 'ghost',
          [style.loading]: this.loader,
        }}>
        <span>
          {this.loader ? <span class={style.loader} /> : null}
          <slot />
        </span>
      </button>
    );
  }
}
