import { Component, BaseComponent, Prop } from '@zento-lib/components';

import { IRippleEffect } from './RippleEffect.d';
import style from './style.scss';

/**
 * Ripple Effect
 *
 * RippleEffect component allowing you to add animation on other components.
 **/
@Component({})
export class RippleEffect extends BaseComponent<IRippleEffect, unknown> {
  /**
   * A value which will determine the effect color style
   */
  @Prop({ type: String, default: '#1c1c1c80' })
  color?: string;

  data() {
    return {
      ripples: [],
      rippleWidth: 0,
      halfRippleWidth: 0,
    };
  }

  mounted() {
    this.$nextTick(() => {
      const width = (this.$refs.rippleContainer as any)?.offsetWidth;
      const height = (this.$refs.rippleContainer as any)?.offsetHeight;

      this.$data.rippleWidth = width > height ? width : height;
      this.$data.halfRippleWidth = this.$data.rippleWidth / 2;
    });
  }

  /**
   * Method that adds a ripple effect on user interaction
   * Also determines the position of the effect in the container
   */
  addRipple(event: MouseEvent) {
    const options = (this.$refs.rippleContainer as any).getBoundingClientRect();
    const rippleId = Date.now();

    this.$data.ripples.push({
      id: rippleId,
      top: `${event.clientY - options.top - this.$data.halfRippleWidth}px`,
      left: `${event.clientX - options.left - this.$data.halfRippleWidth}px`,
      width: `${this.$data.rippleWidth}px`,
      height: `${this.$data.rippleWidth}px`,
    });
  }

  /**
   * Remove the effect from DOM after the user interaction is done
   */
  purgeRipples() {
    setTimeout(() => {
      this.$data.ripples = [];
    }, 200);
  }

  render() {
    return (
      <div
        onMouseDown={(event: MouseEvent) => this.addRipple(event)}
        onMouseUp={this.purgeRipples}
        ref='rippleContainer'
        class={style.rippleOuter}>
        <div class={style.ripples}>
          {this.$data.ripples.map((ripple) => {
            return (
              <div
                key={ripple.id}
                class={style.ripple}
                style={{
                  top: ripple.top,
                  left: ripple.left,
                  width: ripple.width,
                  height: ripple.height,
                  background: this.color,
                }}
              />
            );
          })}
        </div>
        <slot />
      </div>
    );
  }
}
